<template>
<div>

  <div class="ml-4 mr-4 mt-4">
    <h1>Change Password</h1>
    <div class="forms" v-loading="loaders">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form"  @submit.prevent="handleSubmit(changePassword)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <base-input
                  v-if="$store.getters.getAuthUser.type == 'super_admin' || ($store.getters.getAuthUser.type != 'super_admin' && $store.getters.getAuthUser.is_password_changed)"
                  type="password"
                  class=""
                  :rules="{required: $store.getters.getAuthUser.type == 'super_admin' || ($store.getters.getAuthUser.type != 'super_admin' && $store.getters.getAuthUser.is_password_changed)}"
                  name="Old Password"
                  label="Old Password*" v-model="request.old_password"
                  placeholder="*****">
                </base-input>
              </div>
              <div class="col-lg-12 col-md-12">
                <base-input
                  type="password"
                  class=""
                  :rules="{required: true, min: 8}"
                  name="Password"
                  label="New Password*" v-model="request.password"
                  placeholder="*****">
                </base-input>
              </div>
              <div class="col-lg-12 col-md-12">
                <base-input
                  class=""
                  type="password"
                  :rules="{required: true, min: 8}"
                  name="Password Confirmation"
                  label="Confirm New Password*" v-model="request.password_confirmation"
                  placeholder="*****">
                </base-input>
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="changePassword"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          Change Password
        </base-button>
      </validation-observer>
    </div>
  </div>
</div>
</template>

<script>
import swal from "sweetalert2";
import authHelper from "../../../util/authHelper";

export default {
  name: "ChangePassword",
  data() {
    return {
      loaders : false,
      request : {
        old_password:'',
        password:'',
        password_confirmation:'',
      },

    }
  },
  methods: {
    checkAlphanumeric(password) {
      const letter = /[a-zA-Z]/;
      const number = /[0-9]/;
      const valid = number.test(password) && letter.test(password);
      return valid;
    },

    async changePassword(){
      const isChangePasswordFormValid = await this.$refs['formValidator'].validate();
      if (isChangePasswordFormValid) {
        let vm = this;
        if (!(this.checkAlphanumeric(this.request.password) && this.checkAlphanumeric(this.request.password_confirmation)))
        {
          await vm.$notify.error({
            title: 'Error',
            message: 'Password must contain at least one character and number',
          })
          return
        }
        vm.loaders = true
        axios.post(`${this.$store.getters.getBaseUrl}/api/change-password`, {
          old_password:vm.request.old_password,
          password:vm.request.password,
          password_confirmation:vm.request.password_confirmation
        })
          .then((response)=>{
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            let message = response.data.message;
            authHelper.setIsPasswordChanged(1);
            vm.$notify.success({
              title : 'Change Password',
              message: message
            });
            this.$router.push({name: 'Dashboard'}).catch(()=>{});;
          })
          .catch((error)=>{
            let message = 'Something went wrong please try again in few minutes.';
            console.log(error.response);

            vm.$notify.error({
              title : 'Change Password',
              message: error.response.data.message
            })
          })
        .finally(() => {
          vm.loaders = false;
        });
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please fill out all required fields'
        });
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    if (authHelper.getIsPasswordChanged() == 0) {
      swal.fire({
        title: 'Change Password',
        text: `Change your password first. Then you will be able to continue.`,
        type: 'warning',
        confirmButtonColor: '#832C44',
      })
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style scoped>

</style>
